<template>
    <div class="template-history-comp">
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>
  </template>
  
  <script>
  import finalTable from "@/components/FinalTable";
  import { getServiceGuideHistory } from "@/api/ruge/jwPark/serviceGuide";
  export default {
    components: {
      finalTable,
    },
    props: {
      id: {
        required: true,
      },
    },
    watch: {
      id: {
        handler() {
          this.getTableList();
        },
        immediate: true,
      },
    },
    data() {
      return {
        dataset: {
          paginationConfig: {
            need: true,
          },
          filterLine: {
            show: true,
          },
          selection: {
            need: false,
            prop: "productId",
            width: "18",
          },
          header: [
            { prop: "createTime", label: "下载时间", width: "400" },
            { prop: "userName", label: "发送用户", width: "" },
            { prop: "access", label: "获取途径", width: "" },
            { prop: "mail", label: "邮箱", width: "" },
          ],
          tableData: [],
          // 搜索行配置
          searchLineConfig: {
            createTime: {
              type: "dateRange",
              actionType: "goSearch",
              value: [null, null],
            },
          },
          // 表格内容配置
          detailConfig: {
            createTime: {
              type: "dateFormat",
            },
          },
          // 高级搜索配置
          advanceFilterConfig: {},
          pageVO: {
            current: 1,
            rowCount: 10,
            total: 0,
          },
        },
        listQuery: {
          id: null,
          startTime: null,
          endTime: null,
          current: 1,
          rowCount: 10,
        },
      };
    },
    methods: {
      tableEventHandler(datas) {
        if (datas.type === "goSearch") {
          if (datas.params.createTime && datas.params.createTime.length > 0) {
            this.listQuery.startTime =
              datas.params.createTime[0] > 0
                ? new Date(datas.params.createTime[0]).getTime()
                : null;
            this.listQuery.endTime =
              datas.params.createTime[1] > 0
                ? new Date(datas.params.createTime[1]).getTime()
                : null;
            delete datas.params.createTime;
          } else {
            this.listQuery.endTime = null;
            this.listQuery.startTime = null;
          }
          this.listQuery = { ...this.listQuery, ...datas.params };
          this.listQuery.current = 1;
          this.getTableList();
        } else if (datas.type === "paginationChange") {
          this.listQuery.current = datas.params.current.page;
          this.listQuery.rowCount = datas.params.current.limit;
          this.getTableList();
        }
      },
      getTableList() {
        this.listQuery.id = this.id;
        const params = { ...this.listQuery };
        getServiceGuideHistory(params).then((res) => {
          this.dataset.pageVO.total = res.data.total;
          this.dataset.tableData = res.data.rows;
        });
      },
    },
  };
  </script>
  
  <style scoped lang="less">
  .template-history-comp {
    ::v-deep .el-table {
      max-height: calc(90vh - 175px);
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  </style>