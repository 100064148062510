import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 办事指南 - 分页查询
 * @param params
 */
export function getServiceGuideList(params) {
  return request({
    url:
      envInfo.bgApp.customerServiceJWPath +
      '/serviceGuide/queryServiceGuideList',
    method: 'get',
    params,
  });
}

/**
 * 办事指南 - 新增指南
 * @param params
 */
export function addServiceGuide(params) {
  return request({
    url: envInfo.bgApp.customerServiceJWPath + '/serviceGuide/addServiceGuide',
    method: 'post',
    data: params,
  });
}

/**
 * 办事指南 - 下载记录
 * @param params
 */
export function getServiceGuideHistory(params) {
  return request({
    url:
      envInfo.bgApp.customerServiceJWPath +
      '/serviceGuide/queryServiceGuideSendLogList',
    method: 'get',
    params,
  });
}

/**
 * 办事指南 - 修改状态
 * @param params
 */
export function updateServiceGuideState(params) {
  return request({
    url:
      envInfo.bgApp.customerServiceJWPath +
      '/serviceGuide/updateServiceGuideStatus',
    method: 'post',
    data: params,
  });
}

/**
 * 办事指南 - 删除
 * @param params
 */
export function deleteServiceGuide(params) {
  return request({
    url:
      envInfo.bgApp.customerServiceJWPath + '/serviceGuide/deleteServiceGuide',
    method: 'post',
    data: params,
  });
}

/**
 * 办事指南 - 下载
 * @param params
 */
export function downloadServiceGuide(params) {
  return request({
    url: envInfo.bgApp.customerServiceJWPath + '/serviceGuide/download',
    method: 'get',
    params,
  });
}
