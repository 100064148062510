import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/**
 * 类别查询
 * @param params
 */
export function getClassifyList(params) {
  return request({
    url: envInfo.bgApp.lookupPath + '/lookup/classify/list',
    method: 'get',
    params
  })
}

/**
 * 新建类别
 * @param params
 */
export function createClassify(params) {
  return request({
    url: envInfo.bgApp.lookupPath + '/lookup/classify/create',
    method: 'post',
    data: params
  })
}

/**
 * 更新类别
 * @param params
 */
export function updateClassify(params) {
    return request({
        url: envInfo.bgApp.lookupPath + '/lookup/classify/edit',
        method: 'post',
        data: params
    })
}

/**
 * 删除类别
 * @param params
 */
export function deleteClassify(params) {
  return request({
    url: envInfo.bgApp.lookupPath + `/lookup/classify/delete`,
    method: 'delete',
    params
  })
}

/**
 * 查询item
 * @param params
 */
export function getItemList(params) {
    return request({
        url: envInfo.bgApp.lookupPath + `/lookup/item/find`,
        method: 'get',
        params
    })
}

/**
 * 删除item
 * @param params
 */
export function deleteItem(params) {
    return request({
        url: envInfo.bgApp.lookupPath + '/lookup/item/delete',
        method: 'post',
        data: params
    })
}

/**
 * 添加item
 * @param params
 */
export function addItem(params) {
    return request({
        url: envInfo.bgApp.lookupPath + '/lookup/item/add',
        method: 'post',
        data: params
    })
}

/**
 * 修改item
 * @param params
 */
export function updateItem(params) {
    return request({
        url: envInfo.bgApp.lookupPath + '/lookup/item/edit',
        method: 'post',
        data: params
    })
}

/**
 * 修改item排序
 * @param params
 */
export function changeIndex4Item(params) {
    return request({
        url: envInfo.bgApp.lookupPath + '/lookup/item/changeIndex',
        method: 'post',
        data: params
    })
}


/**
 * 执行指定类别的子项
 * @param classifyCode
 */
export function findLookupItem(classifyCode) {
  let params = {'classifyCode': classifyCode}
  return request({
      url: envInfo.bgApp.lookupPath + '/lookup/item/find/list',
      method: 'get',
      params
  })
}